/* You can add global styles to this file, and also import other style files */
.rendicion-debito-pagado {
    background-color: rgb(156, 236, 153) !important;
}

.ag-theme-alpine .ag-cell {
  border: 1px solid transparent !important;
   line-height: 20px !important;
}

.ag-header-cell-text{
  font-size: 10px !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,1) !important;
}

.invalid-feedback{
  display: inline !important;
}

.text-informacion{
  font-size: 14px;
}


.modal-backdrop{
  z-index: 1029 !important;
}

.form-control-sm {
  font-size: .85rem !important;
  padding: 0.1rem 0.5rem !important;
}


