/* *{
    padding: 0;
    margin: 0;
} */
.parent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.menu-ppal {
    grid-area: 1 / 1 / 2 / 6;
}
.dropdown-menu{
    font-size: 14px;
    /* background-color: #073b4c; */
    background: rgb(25,100,126);
    background: -moz-linear-gradient(183deg, rgba(25,100,126,1) 0%, rgba(31,132,164,1) 100%);
    background: -webkit-linear-gradient(183deg, rgba(25,100,126,1) 0%, rgba(31,132,164,1) 100%);
    background: linear-gradient(183deg, rgba(25,100,126,1) 0%, rgba(31,132,164,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#19647e",endColorstr="#1f84a4",GradientType=1);
}
.dropdown-item{
    color: #fff;
}
.aside-y-contenido {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    position: relative;
}
.side-menu{
    background-color: #022c3a;
    /* filter: opacity(70%); */
    position: fixed;
    z-index: 1029;
    height: 100%;
    width: 60px;
    font-size: 12px;
    /* text-align: center; */
}
.side-menu ul{
    margin: 0 auto;
    padding:5em 0px 0px 0px;
}
.side-menu ul li{
    list-style-type: none;
    margin: 50px 0px;
    color: rgb(223, 223, 223);
}
.side-menu ul li > a{
    text-decoration: none;
    color: rgb(205, 205, 205);
}
.side-menu ul li > a:hover{
    color: rgb(255, 255, 255);
}
.content-wrapper{
    position: absolute;
    left: 52px;
    top: 61px;
    right: 1px;
    padding-top: 15px;

}



/* PERSONALIZACIÓN DEL NAVBAR (MENU PRINCIPAL)*/

  /*desaparezco el caret ( triangulo ) en los titulos principales*/
  .general .dropdown > a:after {
    display: none !important;
}
 /*AQUI DETERMINO EL ANCHO MINIMO DEL MENU INTERIOR DEL DESPLEGABLE*/
  .dropdown-submenu {
    position:relative;
    min-width:200px;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:15.7em; /* DISTANCIA DEL SUBMENÚ (DROPDOWUN) */
    margin-top:-6px;
}

/* ESTO GIRA EL CARET AL PASAR POR ENCIMA EN EL DROPDOWN */
.dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
}

/*PERSONALIZO EL LA DISTANCIA DEL SUBMENU DROPDOWN CUANDO ESTA ES MAS ANCHA QUE LA NORMAL*/
.administracion .dropdown-submenu>.dropdown-menu{
    left: 275px !important;
}

.card-group, a{
    text-decoration: none;
    color: #6c757d;
  }
  .card-group, a:hover{
    color: #6c757d;
  }

  table{
    font-size: 13px;
  }
